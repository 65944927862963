td.Mui-TableBodyCell-DetailPanel {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

td.MuiTableCell-root.MuiTableCell-body:not(:first-child),
th.MuiTableCell-root.MuiTableCell-head:not(:first-child){
    padding-left: 5px;
    padding-right: 5px;
}
