html {
  font-family: "Nunito", sans-serif;

  --font-color-dark: #1e1e1e;
  --font-color-light: #a3a3a3;

  --font-color-table-dark: #101828;
  --font-color-table-gray: #667085;
  --font-color-table-lightGray: #a3a3a3;
}

body,
p {
  margin: 0;
  padding: 0;
}

.container {
  padding: 5%;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.logos-container {
  display: flex;
  justify-content: end;
  align-items: center;
}

.melior-logo {
  width: 15%;
  object-fit: contain;
  height: fit-content;
}

.facility-logo {
  width: 10%;
  object-fit: contain;
}

.issue-date-text {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.report-note {
  padding-top: 20px;
}

/* Start: Fonts */
.font-size-xl {
  font-size: 2vw;
  letter-spacing: -0.02em;
}

.font-size-l {
  font-size: 1.2vw;
  letter-spacing: -0.02em;
}

.font-size-m {
  font-size: 1vw;
  letter-spacing: -0.02em;
}

.font-size-s {
  font-size: 0.8vw;
  letter-spacing: 0em;
}

.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}

.font-color-light {
  color: var(--font-color-light);
}

.font-color-black {
  color: var(--font-color-dark);
}

.font-color-footer {
  color: #98a2b3;
}
/* End: Fonts */

/* Start: Table Related */
.table-container {
  border: 1px solid #98a2b3;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 20px;
}
.table-container-label {
  padding: 5px 10px;
}
table {
  width: 100%;
}

/* Start: Table Header Styling */
table th {
  color: var(--font-color-table-gray);
  background-color: #f9fafb;
  text-align: left;
  font-weight: 600;
}
/* Style header columns except the first column */
.container table th:not(:first-child) {
  font-weight: 700;
  width: 15%;
}
/* End: Table Header Styling */

table td {
  border-bottom: 1px solid #eaecf0;
}

table th,
table td {
  padding: 5px 10px;
}
/* Remove border-bottom from last row */
table tr:nth-last-child(1) td {
  border-bottom: none;
}

/* Color the 2nd and 3rd column of all rows after after the header */
table tbody td:not(:first-child) {
  color: var(--font-color-table-gray);
}
/* End: Table Related */

.closing-text p {
  line-height: 45px;
}

.footer {
  margin-top: 50px;
  margin-bottom: 20px;
  border-top: 1px solid #eaecf0;
  padding-top: 10px;

  display: flex;
  justify-content: space-between;
}

/* Weekly/Monthly/Unopened/Results */
.headline {
  text-align: center;
  margin-bottom: 10px;
}

/* Start: Printing Styles */
@media print {
  .font-size-xl {
    font-size: 3vw;
    letter-spacing: -0.02em;
  }

  .font-size-l {
    font-size: 2.5vw;
    letter-spacing: -0.02em;
  }

  .font-size-m {
    font-size: 2vw;
    letter-spacing: -0.02em;
  }

  .font-size-s {
    font-size: 1.5vw;
    letter-spacing: 0em;
  }

  .table-container {
    border: 1px solid #98a2b3;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 2%;
  }

  .closing-text {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
/* End: Printing Styles */
