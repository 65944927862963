body {
  margin: 0;
  font-family: Avenir-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Nunito", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;

  /*overwrites arabic font family*/
  /* font-family: Nunito !important; */
}

@font-face {
  font-family: BalooBhaijaan2;
  src: url(./assets/fonts/BalooBhaijaan2-VariableFont_wght.ttf);
}
@font-face {
  font-family: BalooBhaijaan2-Regular;
  src: url(./assets/fonts/BalooBhaijaan2-Regular.ttf);
}
@font-face {
  font-family: BalooBhaijaan2-Medium;
  src: url(./assets/fonts/BalooBhaijaan2-Medium.ttf);
}
@font-face {
  font-family: BalooBhaijaan2-SemiBold;
  src: url(./assets/fonts/BalooBhaijaan2-SemiBold.ttf);
}
@font-face {
  font-family: Avenir-Black;
  src: url(./assets/fonts/AvenirLTStd-Black.otf);
}
@font-face {
  font-family: Avenir-Heavy;
  src: url(./assets/fonts/AEH.ttf);
}
@font-face {
  font-family: Avenir-Medium;
  src: url(./assets/fonts/Avenir-Medium.ttf);
}
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
